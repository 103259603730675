.lobbyTopContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
}

.lobbyTopItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 6rem;
	padding: 0 20px;
	color: black;
}

.lobbyTopItem .iconMember {
	width: 40px;
	margin-left: 0px !important;
	padding: 7px;
	background-color: rgba(255, 165, 80, 0.3);
	;
	border-radius: 50%;
}

.lobbyTopItem .iconPlayer {
	width: 40px;
	margin-left: 0px !important;
	padding: 7px;
	background-color: rgba(255, 165, 80, 0.8);
	border-radius: 50%;
}

.lobbyTopDivider {
	width: 1px;
	height: 30px;
	border: 1px solid rgba(255, 165, 80, 0.3);
}

.hr-line {
	border: 1px solid rgba(255, 165, 80, 0.3);
	opacity: 1 !important;
	margin: 0.5rem 0 !important;
}

.hr-line-mobile {
	border: 1px solid rgba(255, 165, 80, 0.3);
	opacity: 1 !important;
	margin: 0.5rem 30px !important;
}

.offcanvas-body {
	background-color: coral !important;
}

.sidebar {
	background: coral !important;
}

.nav-item {
	position: relative;
}

/* .nav-item.active .nav-link,
.nav-item.active .logout-nav,
.text-black {
	color: black;
} */

.provider-link.active {
	background-color: #e0e0e0;
}

.subnav {
	background-color: white !important;
	border-top: 1px solid rgb(211, 211, 211);
	margin-bottom: -1px;
}

.nft .slick-dots {
	bottom: 5px !important;
}

.slick-slide img {
	aspect-ratio: 0 !important;
	height: 240px !important;
	width: 100% !important;
	object-fit: contain !important;
}

.slick-slide-text {
	font-size: 16px;
	margin-bottom: 20px;
}

@media (max-width: 767px) {
	.slick-slide img {
		aspect-ratio: 0 !important;
		height: 100% !important;
		width: 100% !important;
	}

	.slick-slide-text {
		font-size: 12px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
}